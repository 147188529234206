import React from "react";

import CallToAction from "../components/call-to-action";
import FAQs from "../components/faqs";
import Footer from "../components/footer";
import Metadata from "../components/metadata";

const FAQPage = () => {
  return (
    <>
      <Metadata title="Frequently Asked Questions - StandupWizard" />
      <FAQs />
      <CallToAction />
      <Footer />
    </>
  );
};

export default FAQPage;
