import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import React from "react";

import Header from "../header";

const faqs = [
  {
    question: "How many standups can I create?",
    answer:
      "You can create unlimited standups for your team. The only limitation is how many total participants are remaining under your current team's plan. This can be found under the 'Billing' tab.",
  },
  {
    question:
      "How do I know how many participants are left under my team's plan?",
    answer:
      "This can be found under the 'Billing' tab. In addition, when editing or creating a standup, you will see the remaining count as well.",
  },
  {
    question: "What is a participant?",
    answer:
      "A participant is any user that is part of a standup. Each participant counts towards your team's total participant cap. For example, if you have 30 users in your Slack workspace but only add 8 to standups, only 8 are counted towards your participant cap.",
  },
  {
    question: "Who can create standups?",
    answer:
      "By default, anyone in your Slack workspace can create standups if they have access to Slack login.",
  },
  {
    question: "How can I limit who can create standups?",
    answer:
      "If you are an admin in your Slack workspace, you will also be an admin on StandupWizard for your team. Simply login and navigate to the 'Admin' tab at the top and you can limit standup creation to only admins and selected users in the workspace.",
  },
  {
    question:
      "Can I look at other standups on my team if I didn't create them?",
    answer:
      "If you are an admin in your Slack workspace, you will also be an admin on StandupWizard for your team. Simply login and navigate to the 'Admin' tab and you'll see all standups your team has created. You can view each standup, see reports, or even edit the standup.",
  },
  {
    question: "Can I create standups in the participant's timezone?",
    answer:
      "Yes! When creating a standup, select 'Use participant timezone' and when the automated standup reminder goes out, it will go out in each participant's timezone.",
  },
  {
    question: "Can I limit how long a participant has to report for a standup?",
    answer:
      "Yes! When creating a standup, under the 'Schedule' section you can toggle 'Limit Standup Reporting Length' and provide a value up to 48 hours. This will allow participants to post standup reports for only up to the hours provided after the standup time.",
  },
  {
    question:
      "Can a participant respond before the standup notification is sent?",
    answer:
      "Definitely! If a participant messages 'report' to the StandupWizard bot on Slack, it will respond with standups that they are able to report on, if applicable. If a participant manually reports before receiving the automated reminder, they just won't receive the reminder that day.",
  },
  {
    question: "Can I look at report history for a standup?",
    answer:
      "Yes! In addition to looking in the broadcast channel on Slack, a standup owner or Workspace admin can login to the dashboard, select the standup, and view all reports for all participants on any given day.",
  },
  {
    question: "Can I look at report history for a given participant only?",
    answer:
      "Yes! A standup owner or Workspace admin can login to the dashboard, select the standup, and click on the user they would like to see a history for. Next, they can select the date range and see all reports done by the selected user in the selected time range.",
  },
  {
    question: "Can I change the default standup questions or add more?",
    answer:
      "Yes! Each standup comes with a default set of questions that you can edit or delete. You can also add new questions. Each standup is limited to a maximum of 5 questions.",
  },
  {
    question:
      "Can I set reminders to remind participants their standup report is due?",
    answer:
      "Yes! You can choose to remind an hour, 45 minutes, 30 minutes, or 15 minutes before the scheduled end date of a standup.",
  },
  {
    question: "Can I create a standup that doesn't run every week?",
    answer:
      "Yes! You can change the frequency of the standup to run every week, every other week, every 3 weeks, or every 4 weeks on the selected report days.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const FAQs = () => {
  return (
    <div className="pt-6 pb-20 bg-white">
      <Header />
      <div className="relative overflow-hidden">
        <div
          className="hidden absolute inset-y-0 h-full w-full z-0 lg:block"
          aria-hidden="true"
        >
          <div className="relative h-full">
            <svg
              className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>

        <main className="relative pt-24">
          <h1 className="text-5xl px-4 font-extrabold text-gray-900 sm:text-center">
            Frequently Asked Questions
          </h1>
          <p className="mt-5 text-xl px-4 text-gray-500 max-w-2xl mx-auto sm:text-center">
            Find answers to common questions. If you can't find an answer here,
            take a look at our{" "}
            <a
              className="text-indigo-500 font-medium"
              href="https://help.standupwizard.com"
            >
              Help Center
            </a>{" "}
            or reach out to our{" "}
            <Link className="text-indigo-500 font-medium" to="/contact">
              customer support
            </Link>
            .
          </p>
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                            <span className="font-medium text-gray-900">
                              {faq.question}
                            </span>
                            <span className="ml-6 h-7 flex items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-6 w-6 transform",
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default FAQs;
